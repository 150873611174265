var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_vm._v("List of awarded items. Click Download to save a list of the awarded products and their details, including prices and costs.")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-right mb-2"
  }, [_c('el-dropdown', [_c('el-button', {
    staticClass: "ml-2",
    attrs: {
      "size": "small",
      "trigger": "click"
    }
  }, [_c('mdicon', {
    attrs: {
      "name": "microsoft-excel",
      "size": 18
    }
  }), _vm._v(" Download "), _c('mdicon', {
    attrs: {
      "name": "menu-down",
      "size": 20
    }
  })], 1), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload(1);
      }
    }
  }, [_vm._v(" Currency Formatted ")]), _c('el-dropdown-item', {
    nativeOn: {
      "click": function ($event) {
        return _vm.awardDownload(0);
      }
    }
  }, [_vm._v(" Unformatted ")])], 1)], 1)], 1)]), _c('el-card', {
    staticClass: "box-card mb-2"
  }, [_c('h5', [_c('span', {
    staticClass: "text-success"
  }, [_vm._v("Total")]), _vm._v(" " + _vm._s(_vm.total_cost ? _vm.total_cost : "$0") + " | "), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("Total Bundle Savings")]), _vm._v(" " + _vm._s(_vm.CombinationUtil.getGrandTotalByCol("total_bundle_saving", _vm.tableDataRaw)) + " | "), _c('span', {
    staticClass: "text-warning"
  }, [_vm._v("Total Savings")]), _vm._v(" " + _vm._s(_vm.CombinationUtil.getGrandTotalByCol("total_current_saving", _vm.tableDataRaw)) + " ")])]), _vm.isBuyer ? _c('el-card', {
    staticClass: "box-card mb-2"
  }, [_c('strong', [_vm._v("Feedback:")]), _c('br'), _vm._v(" " + _vm._s(this.rft.feedback) + " ")]) : _vm._e(), _vm._l(_vm.tableDataBids, function (product, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-3 mb-3"
    }, [_c('el-card', {
      key: index,
      staticClass: "box-card"
    }, [_c('div', {
      staticClass: "clearfix"
    }, [_c('div', {
      staticClass: "mt-2",
      staticStyle: {
        "float": "left"
      }
    }, [_c('strong', {
      domProps: {
        "innerHTML": _vm._s(product.requested_product_name_list_mode)
      }
    }), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.min_shelf_life_months) + " ")]), _vm._v(_vm._s(product.min_shelf_life_months) + " month(s) | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_brand))]), _vm._v(" " + _vm._s(product.current_brand) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_supplier))]), _vm._v(" " + _vm._s(product.current_supplier) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_unit_measure))]), _vm._v(" " + _vm._s(product.current_unit_measure)), _c('br'), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.unit_quantity))]), _vm._v(" " + _vm._s(product.unit_quantity) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_unit_price))]), _vm._v(" " + _vm._s(product.current_unit_price) + " | "), _c('span', {
      staticClass: "text-success"
    }, [_vm._v(_vm._s(_vm.CombinationUtil.headermap_product.current_total))]), _vm._v(" " + _vm._s(product.current_total) + " ")])])]), _c('el-card', {
      key: 'b' + index,
      staticClass: "box-card"
    }, [_c('el-table', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "data": product.bids
      }
    }, [_c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.trade_product_name,
        "width": "500"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.organisation_name)), _c('br'), scope.row.bundle_id ? _c('mdicon', {
            attrs: {
              "name": "package-variant-closed",
              "size": 18
            }
          }) : _vm._e(), _vm._v(" " + _vm._s(scope.row.trade_product_name) + " "), scope.row.bundle_id ? _c('span', {
            attrs: {
              "tag": '00' + scope.row.bundle_id
            }
          }, [_c('br'), _vm._v(" Bundle Id: " + _vm._s(scope.row.bundle_uuid) + " ")]) : _vm._e()];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": "",
        "min-width": "120"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_c('el-button', {
            attrs: {
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.handleBidInfo(scope.row, product);
              }
            }
          }, [_vm._v(" View Bid ")]), scope.row.bundle_id ? _c('el-button', {
            attrs: {
              "size": "small"
            },
            on: {
              "click": function ($event) {
                $event.preventDefault();
                return _vm.handleBundleInfo(scope.row);
              }
            }
          }, [_vm._v(" View Bundle ")]) : _vm._e()];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.pbs_price,
        "align": _vm.CombinationUtil.getColAlign('pbs_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          var _scope$row, _scope$row$raw, _scope$row$raw$produc, _scope$row2, _scope$row2$raw, _scope$row2$raw$produ;
          return [_vm._v(" " + _vm._s(scope !== null && scope !== void 0 && (_scope$row = scope.row) !== null && _scope$row !== void 0 && (_scope$row$raw = _scope$row.raw) !== null && _scope$row$raw !== void 0 && (_scope$row$raw$produc = _scope$row$raw.product) !== null && _scope$row$raw$produc !== void 0 && _scope$row$raw$produc.PBSPrice ? _vm.NumberUtil.formatCurrency(scope === null || scope === void 0 ? void 0 : (_scope$row2 = scope.row) === null || _scope$row2 === void 0 ? void 0 : (_scope$row2$raw = _scope$row2.raw) === null || _scope$row2$raw === void 0 ? void 0 : (_scope$row2$raw$produ = _scope$row2$raw.product) === null || _scope$row2$raw$produ === void 0 ? void 0 : _scope$row2$raw$produ.PBSPrice, false, 2, 2) : "N/A") + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.pack_price,
        "align": _vm.CombinationUtil.getColAlign('pack_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.pack_price) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.single_price,
        "align": _vm.CombinationUtil.getColAlign('single_price')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.single_price) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.total,
        "align": _vm.CombinationUtil.getColAlign('total')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.total) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.total_bundle_saving,
        "align": _vm.CombinationUtil.getColAlign('total_bundle_saving')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.total_bundle_saving) + " ")];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": _vm.CombinationUtil.headermap_bid.total_current_saving,
        "align": _vm.CombinationUtil.getColAlign('total_current_saving')
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (scope) {
          return [_vm._v(" " + _vm._s(scope.row.total_current_saving) + " ")];
        }
      }], null, true)
    })], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "rft-active-product-modal"
  }, [_c('RftEditActiveProductModal', {
    ref: "RftEditActiveProductModal"
  })], 1), _c('div', {
    staticClass: "rft-active-bundle-modal"
  }, [_c('RftEditActiveBundleModal', {
    ref: "RftEditActiveBundleModal"
  })], 1), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isLoading,
      expression: "isLoading"
    }],
    staticClass: "footer-total bg-success"
  }, [_vm._v(" Total: " + _vm._s(_vm.total_cost ? _vm.total_cost : "$0") + " ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }